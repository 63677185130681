<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="标题" prop="title">
                <a-input v-model="queryParam.title" placeholder="请输入标题" allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="简介" prop="introduction">
                <a-input v-model="queryParam.introduction" placeholder="请输入简介" allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="内容" prop="content">
                <a-input v-model="queryParam.content" placeholder="请输入内容" allow-clear />
              </a-form-item>
            </a-col>
            <template v-if="advanced">
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons"
                :style="advanced && { float: 'right', overflow: 'hidden' } || {}">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['notice:tuantuan:add']">
          <a-icon type="plus" />新增
        </a-button>
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)"
          v-hasPermi="['notice:tuantuan:edit']">
          <a-icon type="edit" />修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['notice:tuantuan:remove']">
          <a-icon type="delete" />删除
        </a-button>
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['notice:tuantuan:export']">-->
<!--          <a-icon type="download" />导出-->
<!--        </a-button>-->
        <table-setting :style="{ float: 'right' }" :table-size.sync="tableSize" v-model="columns" :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form ref="createForm" @ok="getList" />
      <!-- 数据展示 -->
      <a-table :loading="loading" :size="tableSize" rowKey="id" :columns="columns" :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :pagination="false"
        :bordered="tableBordered">
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="jumpType" slot-scope="text, record">
            <!-- 重写自定义了个字典组件（标签）-->
           <custom-dict-tag :options="customDict.JumpTypeEnum" :value="record.jumpType"/>
        </span>
        <template slot="cover" slot-scope="text, record">
          <div>
            <img v-if="record.cover" :src="record.cover" style="width:60px;height:62px;" preview />
          </div>
        </template>

        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['notice:tuantuan:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['notice:tuantuan:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['notice:tuantuan:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['notice:tuantuan:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination class="ant-table-pagination" show-size-changer show-quick-jumper :current="queryParam.pageNum"
        :total="total" :page-size="queryParam.pageSize" :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange" @change="changeSize" />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { listTuantuan, delTuantuan } from '@/api/notice/tuantuan'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import CustomDictTag from "@/components/DictCustomTag";
import {mapGetters} from "vuex";

export default {
  name: 'Tuantuan',
  components: {
    CreateForm,
    CustomDictTag
  },
  mixins: [tableMixin],
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        title: null,
        introduction: null,
        content: null,
        cover: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [

        {
          title: '标题',
          dataIndex: 'title',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '简介',
          dataIndex: 'introduction',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '封面图',
          dataIndex: 'cover',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'cover' },
        },
        {
          title: '封面图',
          dataIndex: 'cover',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'cover' },
        },
        {
          title: '跳转类型',
          dataIndex: 'jumpType',
          scopedSlots: { customRender: 'jumpType' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '类型名称',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created() {
    this.getList()
  },
  computed: {    ...mapGetters(['customDict'])

  },
  watch: {
  },
  methods: {
    /** 查询团团最新动态列表 */
    getList() {
      this.loading = true
      listTuantuan(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        title: undefined,
        introduction: undefined,
        content: undefined,
        cover: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          return delTuantuan(ids)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel() { }
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download('notice/tuantuan/export', {
            ...that.queryParam
          }, `tuantuan_${new Date().getTime()}.xlsx`)
        },
        onCancel() { }
      })
    }
  }
}
</script>
