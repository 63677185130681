import request from '@/utils/request'

// 查询团团最新动态列表
export function listTuantuan(query) {
  return request({
    url: '/notice/tuantuan/list',
    method: 'get',
    params: query
  })
}

// 查询团团最新动态详细
export function getTuantuan(id) {
  return request({
    url: '/notice/tuantuan/' + id,
    method: 'get'
  })
}

// 新增团团最新动态
export function addTuantuan(data) {
  return request({
    url: '/notice/tuantuan',
    method: 'post',
    data: data
  })
}

// 修改团团最新动态
export function updateTuantuan(data) {
  return request({
    url: '/notice/tuantuan',
    method: 'put',
    data: data
  })
}

// 删除团团最新动态
export function delTuantuan(id) {
  return request({
    url: '/notice/tuantuan/' + id,
    method: 'delete'
  })
}
